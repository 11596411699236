const bootstrapMediaSizes = {
    xsMin: 0,
    smMin: 576,
    mdMin: 768,
    lgMin: 992,
    xlMin: 1200,
    
    xsMax: 575,
    smMax: 767,
    mdMax: 991,
    lgMax: 1199,
    xlMax: 99999,
};

const medias = {
    xs: `min-width: ${bootstrapMediaSizes.xsMin}px) and (max-width: ${bootstrapMediaSizes.xsMax}px`,
    sm: `min-width: ${bootstrapMediaSizes.smMin}px) and (max-width: ${bootstrapMediaSizes.smMax}px`,
    md: `min-width: ${bootstrapMediaSizes.mdMin}px) and (max-width: ${bootstrapMediaSizes.mdMax}px`,
    lg: `min-width: ${bootstrapMediaSizes.lgMin}px) and (max-width: ${bootstrapMediaSizes.lgMax}px`,
    xl: `min-width: ${bootstrapMediaSizes.xlMin}px)`,
    
    xsMin: `min-width: ${bootstrapMediaSizes.xsMin}px`,
    smMin: `min-width: ${bootstrapMediaSizes.smMin}px`,
    mdMin: `min-width: ${bootstrapMediaSizes.mdMin}px`,
    lgMin: `min-width: ${bootstrapMediaSizes.lgMin}px`,
    xlMin: `min-width: ${bootstrapMediaSizes.xlMin}px`,
    
    xsMax: `max-width: ${bootstrapMediaSizes.xsMax}px`,
    smMax: `max-width: ${bootstrapMediaSizes.smMax}px`,
    mdMax: `max-width: ${bootstrapMediaSizes.mdMax}px`,
    lgMax: `max-width: ${bootstrapMediaSizes.lgMax}px`,
    xlMax: `max-width: ${bootstrapMediaSizes.xlMax}px`,
};

export {
    medias,
    bootstrapMediaSizes,
};
